import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import {
  Heading,
  Text,
  Box,
  FormControl,
  FormLabel,
  UnorderedList,
  ListItem,
  Input,
  AlertTitle,
  AlertDescription,
  Divider,
  Button,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const Withdraw = () => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(null);
  const [username, setUsername] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [pendingRequest, setPendingRequest] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const usernameFromToken = decodedToken.username;
        setUsername(usernameFromToken);
        setIsLoggedIn(true);
        if (usernameFromToken) {
          fetchWalletBalance(usernameFromToken);
          const pollingInterval = 2000;
          const intervalId = setInterval(() => fetchWalletBalance(usernameFromToken), pollingInterval);
          return () => {
            clearInterval(intervalId);
          };
        }
      } catch (error) {
        console.error('Invalid token:', error);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const isPending = localStorage.getItem('pendingRequest');
    if (isPending) {
      setPendingRequest(true);
      setShowConfirmation(true);
      setLoading(false);
    }
  }, []);

  const fetchWalletBalance = async (username) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(`https://newproject3-0-veo2.onrender.com/account/${username}/balance`);
        if (response.status === 200) {
          setWalletBalance(response.data.balance);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const validateAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    if (!amount || isNaN(parsedAmount) || parsedAmount <= 0) {
      return 'Please enter a valid amount.';
    }
    if (!Number.isInteger(parsedAmount)) {
      return 'Amount must be an integer.';
    }
    if (parsedAmount < 1000) {
      return 'Minimum withdrawal amount is ₹1000.';
    }
    if (parsedAmount > 100000) {
      return 'Maximum withdrawal limit is ₹100000.';
    }
    if (walletBalance !== null && parsedAmount > walletBalance) {
      return 'Insufficient balance.';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const error = validateAmount(amount);
    if (error) {
      setError(error);
      return;
    }
    if (pendingRequest) {
      setError('You have a pending withdrawal request. Please wait for it to be processed.');
      return;
    }
    if (walletBalance === null) {
      setError('Failed to fetch wallet balance. Please try again later.');
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const response = await axios.post(
        'https://newproject3-0-veo2.onrender.com/redeem', // Adjust the URL to your backend server
        { amount: parseFloat(amount) },
        {
          headers: {
            username, // Send username as a header
          },
        }
      );
      if (response.status === 200) {
        setWithdrawalAmount(parseFloat(amount));
        setShowConfirmation(true);
        onOpen();
        setTimeout(() => {
          setShowConfirmation(false);
          setLoading(false);
        }, 300000); // 5 minutes
      } else {
        // Handle other statuses
        setLoading(false);
        setError('Failed to process withdrawal. Please try again later.');
      }
    } catch (error) {
      console.error('Withdrawal request failed:', error);
      if (error.response) {
        console.log('Server response:', error.response.data);
      }
      setLoading(false);
      setError('Previous Request Pending');
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    setError(validateAmount(value));
  };

  const getAmountInputBorderColor = () => {
    if (!amount) return '';
    return error ? 'red.500' : 'green.500';
  };

  return (
    <Box maxWidth="500px" mx="auto" p="6" bg="gray.50" borderRadius="lg" boxShadow="md">
      <Heading as="h2" size="lg" textAlign="center" mb={6} color="teal.500">
        Withdraw Funds
      </Heading>
      {error && (
        <Text color="red.500" mb={4}>
          {error}
        </Text>
      )}
      <form onSubmit={handleSubmit}>
        <FormControl id="amount" mb={4} isInvalid={!!error}>
          <FormLabel>Amount:</FormLabel>
          <Input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            disabled={loading || showConfirmation || pendingRequest} // Disable input if there is a pending request
            placeholder="Enter amount"
            borderColor={getAmountInputBorderColor()}
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="teal"
          isLoading={loading}
          width={'100%'}
          loadingText="Processing..."
          isFullWidth
          disabled={
            !isLoggedIn || // Disable button if not logged in
            pendingRequest ||
            showConfirmation ||
            walletBalance === null ||
            parseFloat(amount) > walletBalance
          }
        // Disable button if there's a pending request, confirmation is shown, or if balance is insufficient
        >
          Withdraw
        </Button>
      </form>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          mx={{ base: "2", md: "auto" }}
          w={{ base: "80%", md: "500px" }}
          maxW="90%">
          <ModalHeader fontSize={{ base: "lg", md: "xl" }}>Withdrawal Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="success" borderRadius="md" hidden={!showConfirmation}>
              <AlertIcon />
              Your withdrawal request of ₹{withdrawalAmount} has been placed. Please allow 5 minutes for processing.
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box mt={6} width="100%" textAlign="left">
          <Heading as="h3" size="md" color="teal.500" mb={2}>
          Withdrawal Rules
          </Heading>
          <UnorderedList styleType='inherit' color="gray.600" spacing={2}>
            <ListItem>Minimum withdrawal amount: ₹1000 </ListItem>
            <ListItem>Maximum withdrawal limit: ₹100000</ListItem>
            <ListItem>Withdrawals are processed up to 10 minutes </ListItem>
            <ListItem>Withdrawals on weekends may take longer to process</ListItem>
            <ListItem>Transactions made outside of business hours (10 AM to 11 PM IST) may take longer to process.</ListItem>

            {/* Add more rules as needed */}
          </UnorderedList>
        </Box>
        <Box mt={6} width="100%">
          <Heading as="h3" size="md" textAlign="center" color="teal.500" mb={2}>
            Important Information
          </Heading>
          <Alert status="info" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Note:</AlertTitle>
              <AlertDescription display="block">
              Please allow up to 10 minutes for the funds to reflect in your wallet. If you encounter any issues, please contact our support team
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
        <Divider />
    </Box>
  );
};

export default Withdraw;