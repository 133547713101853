import React, { useState, useEffect } from "react";
import { Box, Flex, Button, Text, Input, Grid } from "@chakra-ui/react";
import { motion } from "framer-motion"; // For plane animation

const AviatorGameUI = () => {
  const [multiplier, setMultiplier] = useState(1.0);
  const [isFlying, setIsFlying] = useState(false);
  const [crashPoint, setCrashPoint] = useState(null);
  const [userBalance, setUserBalance] = useState(1000);
  const [betAmount, setBetAmount] = useState(100);
  const [cashOutMultiplier, setCashOutMultiplier] = useState(null);

  // Starts the game (auto-fly)
  const startGame = () => {
    setCrashPoint((Math.random() * 10 + 2).toFixed(2));
    setIsFlying(true);
    setMultiplier(1.0);
    setCashOutMultiplier(null);
  };

  // Multiplier increases as the plane flies
  useEffect(() => {
    if (isFlying) {
      const interval = setInterval(() => {
        setMultiplier((prev) => (prev < crashPoint ? prev + 0.05 : prev));
        if (multiplier >= crashPoint) {
          setIsFlying(false);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isFlying, multiplier, crashPoint]);

  // Cash-out function
  const handleCashOut = () => {
    setCashOutMultiplier(multiplier.toFixed(2));
    setUserBalance(userBalance + betAmount * multiplier);
    setIsFlying(false);
  };

  // Custom styles using Chakra UI's Box, Flex, Grid for layout
  return (
    <Flex
      direction="column"
      align="center"
      bg="#101529" // Dark theme background
      minH="100vh"
      color="white"
    >
      {/* Navbar */}
      <Flex
        w="100%"
        justify="space-between"
        p="20px"
        bg="#1F2937"
        align="center"
      >
        <Text fontSize="2xl" fontWeight="bold" color="#E53E3E">
          AviatorX
        </Text>
        <Text fontSize="xl" color="white">
          Balance: {userBalance} INR
        </Text>
      </Flex>

      {/* Game Area */}
      <Box
        w="90%"
        maxW="900px"
        bg="#1C2331"
        p="20px"
        borderRadius="12px"
        mt="20px"
        position="relative"
      >
        {/* Graph Area */}
        <Box
          w="100%"
          h="300px"
          bg="#202940"
          borderRadius="12px"
          position="relative"
          overflow="hidden"
        >
          {/* Multiplier Text */}
          <Text
            fontSize="5xl"
            color="#F6E05E"
            fontWeight="bold"
            position="absolute"
            top="10px"
            left="20px"
          >
            {isFlying ? `${multiplier.toFixed(2)}x` : "Game Over"}
          </Text>

          {/* Plane Animation */}
          {isFlying && (
            <motion.div
              animate={{ x: `${multiplier * 10}%` }} // Plane moves with the multiplier
              transition={{ duration: 0.1, ease: "linear" }}
              style={{
                position: "absolute",
                bottom: "10px",
                width: "40px",
                height: "40px",
                backgroundImage: `url('/path-to-plane-icon.png')`,
                backgroundSize: "cover",
              }}
            />
          )}
        </Box>

        {/* Cash Out Button */}
        <Box mt="20px">
          {isFlying ? (
            <Button
              colorScheme="red"
              w="100%"
              size="lg"
              onClick={handleCashOut}
              bgGradient="linear(to-r, red.500, red.400)"
            >
              Cash Out at {multiplier.toFixed(2)}x
            </Button>
          ) : (
            <Button
              colorScheme="green"
              w="100%"
              size="lg"
              onClick={startGame}
              bgGradient="linear(to-r, green.400, green.300)"
            >
              Start New Game
            </Button>
          )}
        </Box>
      </Box>

      {/* Betting Area */}
      <Flex
        w="90%"
        maxW="900px"
        justify="space-between"
        mt="30px"
        bg="#1C2331"
        p="20px"
        borderRadius="12px"
      >
        {/* Bet Section */}
        <Box>
          <Text fontSize="2xl" mb="10px">
            Place Bet
          </Text>
          <Input
            value={betAmount}
            onChange={(e) => setBetAmount(Number(e.target.value))}
            type="number"
            size="lg"
            bg="#2D3748"
            color="white"
          />
        </Box>

        {/* Bet Amount Control */}
        <Flex direction="column" align="center" gap="10px">
          <Button
            colorScheme="green"
            onClick={() => setBetAmount(betAmount + 10)}
            size="lg"
            bgGradient="linear(to-r, green.400, green.300)"
          >
            +10
          </Button>
          <Button
            colorScheme="green"
            onClick={() => setBetAmount(betAmount + 50)}
            size="lg"
            bgGradient="linear(to-r, green.400, green.300)"
          >
            +50
          </Button>
        </Flex>

        {/* User Balance */}
        <Box>
          <Text fontSize="2xl" mb="10px">
            Current Balance
          </Text>
          <Text fontSize="3xl" color="yellow.300" fontWeight="bold">
            {userBalance} INR
          </Text>
        </Box>
      </Flex>

      {/* Bet History & User List */}
      <Box
        w="90%"
        maxW="900px"
        bg="#1C2331"
        mt="30px"
        p="20px"
        borderRadius="12px"
      >
        <Text fontSize="2xl" mb="10px">
          Bet History
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <Box bg="#2D3748" p="10px" borderRadius="8px">
            <Text>User1: Bet 100 | Winnings: 200</Text>
          </Box>
          <Box bg="#2D3748" p="10px" borderRadius="8px">
            <Text>User2: Bet 150 | Winnings: 250</Text>
          </Box>
          {/* Add more user bets here */}
        </Grid>
      </Box>
    </Flex>
  );
};

export default AviatorGameUI;
