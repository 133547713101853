import { useState, useEffect, useCallback } from 'react';
import { useWebSocket } from './WebSocketContext';
import {jwtDecode} from 'jwt-decode';

const useWalletBalance = () => {
  const { balance, setBalance } = useWebSocket();
  const [walletBalance, setWalletBalance] = useState(balance);
  const [loading, setLoading] = useState(true);
  const pollingInterval = 1000; // Set to 5 seconds for more frequent updates

  const fetchWalletBalance = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      if (username) {
        try {
          const response = await fetch(`https://newproject3-0-veo2.onrender.com/account/${username}/balance`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setWalletBalance(data.balance !== undefined ? data.balance : 0);
            setBalance(data.balance !== undefined ? data.balance : 0); // Update the context balance
          } else {
            setWalletBalance(0);
            setBalance(0); // Update the context balance
          }
        } catch (error) {
          console.error('Fetch error:', error);
          setWalletBalance(0);
          setBalance(0); // Update the context balance
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [setBalance]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        if (username) {
          fetchWalletBalance();

          const intervalId = setInterval(() => {
            fetchWalletBalance();
          }, pollingInterval);

          return () => {
            clearInterval(intervalId);
          };
        } else {
          console.error('Username not found in token');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [fetchWalletBalance, pollingInterval]);

  useEffect(() => {
    setWalletBalance(balance);
  }, [balance]);

  return { walletBalance, setWalletBalance, fetchWalletBalance, loading };
};

export default useWalletBalance;
